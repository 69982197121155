/* Popover */
.tippy-box {
  background: rgba(70,70,70, 0.85) !important;
  font-size: 11px !important;
  text-align: left !important;
  text-transform: none !important;
}

.tippy-arrow {
  bottom: -3px !important;

}

.tippy-arrow::before {
  border-top-color: rgba(70,70,70, 0.85) !important;
  border-width: 8px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
